import React from 'react';
import { Link } from 'gatsby';
import { render } from 'react-dom';
import ReactRoundedImage from 'react-rounded-image';
import { SocialIcon } from 'react-social-icons';
import Divider from '@material-ui/core/Divider';
import { Helmet } from 'react-helmet';
import Popup from 'reactjs-popup';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import Jojo from '../1Pics/jojo.jpg';
import Marki from '../1Pics/marki.jpg';
import 'reactjs-popup/dist/index.css';
import PLZ from '../components/PLZ';

export default () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Ablauf</title>
    </Helmet>

    <section id="ablauf" className="pt-20 md:pt-20">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            In 90 Sekunden Abholung Buchen
          </h1>
          <br />
          <p className="text-l">
            <p>
              <strong>
                Schritt 1: Wunsch Abholtermin auswählen & Details zur Abholung angeben
              </strong>{' '}
            </p>
            <p align="justify">
              Um dir maximale zeitliche Flexibilität zu gewährleisten, kannst Du im ersten
              Buchungsschritt einen Wunschabholtermin festlegen - wir richten uns voll und ganz nach
              deinem Kalender. Wähle dazu ein gewünschtes Zeitfenster an einem Wochentag deiner Wahl
              aus (wir sind Montags bis Samstags von 08:00 Uhr - 20:00 Uhr für dich da). Die
              nächstmögliche Abholung kann bereits 5 Stunden nach Abschluss der Buchung stattfinden.
              Spätestens in 24h. Damit bieten wir nicht nur die flexibelste, sondern auch die
              schnellste und nutzerfreundlichste Lösung im Bereich der Sperrmüllentsorgung an.
              <p /> <br />
              Im nächsten Buchungsschritt wirst du innerhalb eines 100% digitalisierten und
              intuitiven Systems durch die relevantesten Sperrmüllkategorien navigiert. Um diesen
              Prozessschritt übersichtlich und nutzerfreundlich zu gestalten, kannst Du aus allen
              Arten von Sperrmüll auswählen (Gemischtes Gerümpel, Möbel, Teppich & Textil, Garten &
              Heimwerken, Deko & Accessoires, Bad, Elektrogeräte). So findest du schnell und einfach
              die Gegenstände, die du abgeben möchtest. Durch simples anklicken, kannst du alle
              gewünschten Objekte in Deinen “Sperrmüll-Container” werfen.
              <p> </p> <br />
              Im Hintergrund findet bereits deine individuelle Preiskalkulation in Echtzeit statt -
              dabei wird automatisch das hinterlegte Durchschnittsgewicht der Gegenstände mit dem
              Kilopreis verrechnet (99 Cent pro Kilogramm). So hast du jederzeit einen genauen
              Überblick über die Kostenstruktur. Weil die im System hinterlegten
              Durchschnittsgewichte der Objekte nicht immer zu 100% mit dem Gewicht deiner
              Gegenstände übereinstimmen, werden alle Gegenstände von uns noch einmal gewogen, um
              das genaue Gewicht zu bestimmen.
              <p /> <br />
              Transparenz steht für uns neben Flexibilität, Komfort und Benutzerfreundlichkeit an
              oberster Stelle.
            </p>{' '}
            <br />
            <p>
              <strong>Schritt 2: Weitere Informationen & Checkout</strong>
            </p>
            <p align="justify">
              Damit am Tag der Abholung alles reibungslos funktioniert benötigen wir außerdem einige
              wenige Daten, um mit dir ggf. in Kontakt treten zu können. Hierzu bitten wir dich
              deinen Namen, Vornamen, Anschrift, E-Mail Adresse und deine Telefonnummer anzugeben.
              Zusätzlich musst Du den Ort der Objekte angeben (Keller, Stockwerk, etc.). Im Service
              enthalten ist der nur der Abtransport deiner ausgewählten Gegenstände am Ort deiner
              Wahl - so können wir dir maximalen Komfort gewährleisten. Der Abbau ist der
              Gegenstände ist ausdrücklich nicht enthalten.
              <p />
              <br />
              Als nächstes bekommst du eine Übersicht deiner Abholung und Informationen, die du
              angegeben hast noch einmal angezeigt. Um die Buchung abzuschließen musst du lediglich
              dem Checkoutprozess folgen und den kalkulierten Preis bezahlen. <p />
              <br />
              Im Anschluss erhälst Du eine Bestätigungsmail zu deiner Buchung, sowie eine Rechnung.
            </p>
            Fertig.
            <p />
            <br />
            <p>
              <strong>Schritt 3: Abholung am Wunschtermin</strong>
            </p>
            <p align="justify">
              Am gebuchten Termin kommen wir pünktlich und Co2-kompensiert zu Dir nach Hause und
              laden deine Abholung ein. Im Anschluss entsorgen wir alles ordnungsgemäß. Bitte habe
              am Wunschtermin alles schon abholbereit am Wunschort vorbereitet. So verläuft die
              gesamte Abholung deutlich schneller und stressfreier ab.
            </p>
            re.cycular.
            <br />
            <br />
            <a href="/Abfälle/">
              <strong>Hier findest Du die Abfälle, die re.cycular u.a. NICHT abholt.</strong>{' '}
            </a>
            <p className="mt-8">
              <Button>
                <Popup trigger={<button className="button"> Jetzt Buchen </button>} modal>
                  <div>
                    <PLZ />
                  </div>
                </Popup>
              </Button>
            </p>
          </p>
        </div>
      </div>
    </section>
  </Layout>
);
